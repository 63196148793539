.App {
  text-align: center;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

.App-link {
  color: #61dafb;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Compact table row padding with !important */
.compact-table .ant-table-tbody > tr > td {
  padding: 6px !important; /* Smaller padding with !important */
  font-size: 14px !important; /* Font size adjustment */
}

.compact-table .ant-table-thead > tr > th {
  padding: 4px !important; /* Header cell padding with !important */
}

/* Style to move the filter icon closer to the column name */
.compact-table .ant-table-filter-column .ant-table-filter-trigger {
  display: inline-flex;
  align-items: center;
   /* Adjust as necessary to bring it closer to the column title */
  margin-right: 14px; /* Move it closer to the column name */
}

.joyride-tooltip {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.joyride-tooltip-active {
  opacity: 1;
  transform: translateY(0);
}

.joyride-custom-arrow {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  position: absolute;
  top: -8px; /* Adjust based on tooltip position */
  left: calc(50% - 8px);
  transform: rotate(45deg);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  z-index: -1; /* Keep it behind the tooltip */
}
.documentation-content {
  font-family: "Inter", -apple-system, BlinkMacSystemFont,
               "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 10px;
}


/* Headings */
.documentation-content h1,
.documentation-content h2,
.documentation-content h3,
.documentation-content h4,
.documentation-content h5,
.documentation-content h6 {
  margin-top: 1.2em;
  margin-bottom: 0.6em;
  font-weight: bold;
}

.documentation-content h1 {
  font-size: 2em;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 0.3em;
}

.documentation-content h2 {
  font-size: 1.75em;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.3em;
}

.documentation-content h3 {
  font-size: 1.5em;
}

.documentation-content h4 {
  font-size: 1.25em;
}

/* Paragraphs */
.documentation-content p {
  margin-bottom: 1em;
}

/* Links */
.documentation-content a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.2s ease;
}
.documentation-content a:hover {
  text-decoration: underline;
  color: #357ab8;
}

/* Lists */
.documentation-content ul,
.documentation-content ol {
  margin-left: 1.5em;
  margin-bottom: 1em;
}
.documentation-content li {
  margin-bottom: 0.5em;
}

/* Blockquotes */
.documentation-content blockquote {
  border-left: 4px solid #ddd;
  background: #f9f9f9;
  padding: 12px 16px;
  margin: 1em 0;
  color: #555;
  font-style: italic;
  border-radius: 4px;
}

/* Horizontal Rules */
.documentation-content hr {
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 2em 0;
}

/* Images */
.documentation-content img {
  max-width: 100%;
  border-radius: 4px;
  margin: 1em 0;
}

/* Code Blocks */
.documentation-content pre {
  background-color: #2d2d2d; /* Dark background */
  color: #f8f8f2; /* Light text for contrast */
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
  line-height: 1.5;
}

.documentation-content :not(pre) > code {
  background-color: #f6f8fa;       /* Subtle gray (GitHub-like) */
  color: #24292f;                  /* Dark, readable text */
  font-family: 'Fira Code', 'Source Code Pro', monospace;
  font-size: 85%;                  /* Slightly smaller than normal text */
  padding: 0.2em 0.4em;            /* Enough padding to stand out */
  border: 1px solid #e1e4e8;       /* Light border for definition */
  border-radius: 6px;              /* A bit of rounding */
}

/* Tables */
.documentation-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
  border: 1px solid #d1d1d1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.documentation-content thead {
  background-color: #eaeaea; /* Light grey header */
  color: #333;              /* Dark text for contrast */
}

.documentation-content th,
.documentation-content td {
  padding: 12px 16px;
  border: 1px solid #d1d1d1;
  text-align: left;
}

.documentation-content tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.documentation-content tbody tr:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.example-drawer-content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  padding: 16px;
}

.example-drawer-content .ant-collapse {
  background: #fff;
}

.example-drawer-content .ant-tabs-tab {
  font-weight: 500;
}
.grid-item-wrapper:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}



.documentation-content pre code.hljs {
  background: none !important;
}

.copy-button {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.08);
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  z-index: 9999; /* ensures it's above the code text */
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.copy-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #fff;
}

.copy-btn-icon {
  line-height: 1;
  display: flex;
  align-items: center;
}

.org-switch-button {
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border-radius: 4px;
  padding: 4px 8px;
}

.org-switch-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.org-list-item {
  transition: background-color 0.2s ease;
}

/* Highlight list item on hover */
.org-list-item:hover {
  background-color: #f0f0f0;
}

